import { useState } from "react";
import { useNavigate, useSearchParams  } from "react-router-dom";
import { VerifySmsToken, RegisterToken } from './Data';
import { showError } from "../../shared/layout/Layout.js";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const logon = (jwt, navigate) => {
    sessionStorage.setItem("jwt", jwt);
    if (navigator.serviceWorker.controller !== null) {
        navigator.serviceWorker.controller.postMessage({
            type: 'STORE-TOKEN',
            token: jwt
        });
    }
    navigate("/");    // probably navigate direct to accept terms
}

const SetPasswordSms = function (props) {
    const navigate = useNavigate()

    const [username, setUsername] = useState("");
    const [token, setToken] = useState("");
    const [error, setError] = useState(false);

    const [validated, setValidated] = useState(false);

    if(error){
        return (
            <div className="content">
                <h1>Set up your account</h1>
                <p>
                    We could not find an account with that username, or the code you entered may have timed out or was not entered correctly.
                </p>
                <p>
                    Please try again.
                </p>
                <p>
                    If this does not work, you should contact your practitioner.
                </p>
                <button onClick={() => setError(false)}>Back</button>
            </div>
        )
    }

    if (validated === false) {
        return (
            <div className="content">
                <h1>Set up your account</h1>
                <p>
                    You'll find these details on the text we sent you.
                </p>
                <Formik
                    initialValues={{
                        username: "",
                        token: "",
                    }}
                    validationSchema={Yup.object({
                        username: Yup.string().required('Username is required'),
                        token: Yup.string().max(6).required('Token is required')
                    })}
                    onSubmit={(values) => {
                        setUsername(values.username);
                        setToken(values.token);
                        VerifySmsToken(values.username, values.token).then(v => {
                            if(v) setValidated(v)
                            else setError(true);
                        })
                    }}
                >
                    {(data) => {
                        let error = showError.bind(this, data);
                        return (
                            <Form>
                                <label className={error('username')}>
                                    Enter your username
                                    <Field name="username" />
                                    <span className='error-message'><ErrorMessage name="username" /></span>
                                </label>
                                <label className={error('token')}>
                                    Enter your 6-digit code
                                    <Field name="token" maxLength="6"/>
                                    <span className='error-message'><ErrorMessage name="token" /></span>
                                </label>
                                <button type="submit">Verify</button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        )
    }
    return (
        <div className="content">
            <h1>Set your password</h1>
            <p>Your username is</p>
            <b>{username}</b>
            <br />
            <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: ""
                    }}
                    validationSchema={Yup.object({
                        password: Yup
                            .string()
                            .min(8, 'Your password needs to be at least 8 characters long')
                            .matches(new RegExp('(?=.*[a-z])(?=.*[A-Z])'), 'Your password needs to include both lower and upper case characters')
                            .required('You must enter a password'),
                        confirmPassword: Yup
                            .string()
                            .required('You must enter a password')
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    })}
                onSubmit={(values) => {
                    RegisterToken(username, token, values.password).then(jwt => {
                        if (jwt !== undefined && jwt !== "") logon(jwt, navigate)
                        else setError(true);
                    });
                }}
                >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <p>
                                Your password needs to be at least 8 characters long
                                and contain both upper and lower case characters.
                            </p>
                            <label className={error('password')}>
                                Choose your password
                                <Field name="password" type="password" minLength="8"/>
                                <br />
                                <span className='error-message'><ErrorMessage name="password" /></span>
                            </label>
                            <label className={error('confirmPassword')}>
                                Confirm new password
                                <Field name="confirmPassword" type="password" minLength="8" />
                                <br />
                                <span className='error-message'><ErrorMessage name="confirmPassword" /></span>
                            </label>
                            <button type="submit">Set your password</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const SetPasswordEmail = function (props){
    const [params] = useSearchParams ();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    let invite = params.get("invite");
    let userToken = undefined;
    let setupError = (invite === undefined || invite === "");
    try {
        
        let urlDecoded = decodeURI(invite);
        let base64Decoded = atob(urlDecoded);
        userToken = JSON.parse(base64Decoded);
    } catch {
        setupError = true;
    }
    

    if(error || setupError){
        return (
            <div className="content">
                <h1>Set up your account</h1>
                <p>
                    There was a problem with your email invite link. Please click the link in your 
                    email invitation again.
                </p>
                <p>
                    If this does not work, you should contact your practitioner.
                </p>
            </div>
        )
    }

    return(
    <div className="content">
        <h1>Set up your account</h1>
        <p>Your username is</p>
        <b>{userToken.username}</b>
        <br />
        <Formik
            initialValues={{
                password: "",
                confirmPassword: ""
            }}
            validationSchema={Yup.object({
                password: Yup
                    .string()
                    .min(8, 'Your password needs to be at least 8 characters long')
                    .matches(new RegExp('(?=.*[a-z])(?=.*[A-Z])'), 'Your password needs to include both lower and upper case characters')
                    .required('You must enter a password'),
                confirmPassword: Yup
                    .string()
                    .required('You must enter a password')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
            onSubmit={(values) => {
                RegisterToken(userToken.username, userToken.token, values.password).then(jwt => {
                    if(jwt !== undefined && jwt !== "") {
                        logon(jwt, navigate)
                    }
                    else{
                        setError(true);
                    }
                }).catch(e => {
                    setError(true);
                })
            }}
        >
        {(data) => {
            let error = showError.bind(this, data);
            return (
                <Form>
                    <p>
                        Your password needs to be at least 8 characters long
                        and contain both upper and lower case characters.
                    </p>
                    <label className={error('password')}>
                        Choose your password
                        <Field name="password" type="password" minLength="8"/>
                        <br />
                        <span className='error-message'><ErrorMessage name="password" /></span>
                    </label>
                    <label className={error('confirmPassword')}>
                        Confirm new password
                        <Field name="confirmPassword" type="password" minLength="8" />
                        <br />
                        <span className='error-message'><ErrorMessage name="confirmPassword" /></span>
                    </label>
                    <button type="submit">Set your password</button>
                </Form>
            )
        }}
        </Formik>
        
    </div>)
}

export { SetPasswordSms, SetPasswordEmail };