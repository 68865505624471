import { Outlet } from "react-router-dom";
import { Appointments } from "./Appointments";

const AppointmentsRoutes = {
    path: "appointments",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Appointments />
        }
    ]
};

export default AppointmentsRoutes;