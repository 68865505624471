import React from 'react';
import { Link } from 'react-router-dom';
import './mystuff.scss'
import * as Icon from '../../shared/Icons';
import BackButton from '../../shared/navigation';

function WhoIAm() {
    return (
        <Link to="/mystuff/whoiam">
            <div className="stuffbox stuffbox-whoiam">
                <Icon.WhoIAm />
                <h2>Who I am</h2>
            </div>
        </Link>
    )
}

function WantToBe() {
    return (
        <Link to="/mystuff/whoiwanttobe">
            <div className="stuffbox stuffbox-whoiwant">
                <Icon.WantToBe />
                <h2>Who I want to be</h2>
            </div>
        </Link>
    )
}

function HowGetThere() {
    return (
        <Link to="/mystuff/howillgetthere">
            <div className="stuffbox stuffbox-howiget">
                <Icon.HowGetThere />
                <h2>How I'll get there</h2>
            </div>
        </Link>
    )
}

function AddMoreStuff() {
    return (
        <Link to="/mystuff/add">
            <div className="stuffbox stuffbox-addmore">
                <Icon.AddMoreStuff />
                <h2>Add more stuff</h2>
            </div>
        </Link>
    )
}

function MyStuff() {
    return (
        <div className="homepage content">
            <BackButton />
            <h1>My stuff</h1>
            <div className="stuffbox-container">
                <WhoIAm />
                <WantToBe />
                <HowGetThere />
                <AddMoreStuff />
            </div>
        </div>
    )
}

export { MyStuff, WhoIAm, WantToBe, HowGetThere, AddMoreStuff };