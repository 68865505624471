import React, { useState, useEffect, useContext, createContext } from 'react';
import { Link } from 'react-router-dom';
import { CreateGoal, GetGoals, GetGoalsByStatus, GetGoalsMaxByStatus } from './Data';
import * as Shared from '../../shared/goals/Goals';
import BackButton from '../../shared/navigation';
import { Loader, Pager } from '../../shared/layout/Layout';
import './goals.scss'
import * as Icon from '../../shared/Icons';
import { NotificationContext } from '../notifications/Notifications';
import { ItemArea } from '../../shared/Events';
import { CreateDate, Format } from '../../shared/dates';
import { ModalContext } from '../../shared/Modals';

const GoalContext = createContext("");
GoalContext.displayName = "GoalId";

const sortGoals = (a, b) => parseInt(a.order) - parseInt(b.order)

function SuggestGoal(props) {
    const modalContext = useContext(ModalContext);
    const [show, setShow] = useState(false);

    const onCreate = (goal) => {
        setShow(true);
        let date = CreateDate(goal.deadline);

        const request = {
            "deadline": date,
            "description": goal.description,
            "title": goal.title
        }

        CreateGoal(request).then(() => {
            setShow(false);
            modalContext.setShowTextNavigate("Goal suggested", "/goals");
        });
    }

    return (
        <div className='goals content'>
            <BackButton to="/goals" />
            <h1>Suggest a goal</h1>
            <Shared.EnterDetails title="" desc="" deadline="" text="Submit" submit={onCreate} show={show}/>
        </div>
    )
}

function GoalItem(goal) {
    return (
        <Link to={goal.id + "/tasks"} key={goal.id} className="goal-container">
            <span className='title'>{goal.title}</span>
            <Icon.FrontArrow alt="go to current goal page" />
        </Link>
    )
}

function ActiveGoalItem(goal) {
    return (
        <Link to={goal.id + "/tasks"} key={goal.id} className="goal-container">
            <div className='info'>
                <span className='title'>{goal.title}</span>
                <span className='date'>Due: {Format(goal.deadline).dayYear}</span>
            </div>
            <Icon.FrontArrow alt="go to current goal page" />
        </Link>
    )
}

function Section(props) {
    if (props.loading) {
        return (
            <div className="list">
                <h2>{props.title}</h2>
                <Loader />
            </div>)
    }
    let data = props.goals.length === 0 ? <span>You have no {props.title.toLowerCase()} goals</span> : props.goals;
    return (
        <div className="list">
            <h2>{props.title}</h2>
            {data}
        </div>
    );
}


function Paged(props) {
    if (props.loading) {
        return (
            <div className="list">
                <h2>{props.title}</h2>
                <Loader />
            </div>)
    }

    return (
        <div className="list">
            <h2>{props.title}</h2>
            <Pager {...props} />
        </div>
    );
}

function Goals() {
    const goalId = useContext(GoalContext);
    const notificationContext = useContext(NotificationContext);

    const [activeGoals, setActive] = useState([])
    const [suggestedGoals, setSuggested] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (goalId === undefined) return;
        (async () => {
            let data = await GetGoals();
            data = data.filter(goal => goal.deleted === false);
            let active = data.filter(goal => goal.status === Shared.GoalType.Approved).sort(sortGoals);
            let suggested = data.filter(goal => goal.status === Shared.GoalType.Pending).sort(sortGoals);

            setActive(active);
            setSuggested(suggested);
            setLoading(false);
        })();
    }, [goalId]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Goals);
    }, [notificationContext]);

    const getCompletedMax = () => {
        return GetGoalsMaxByStatus(Shared.GoalType.Completed);
    }
    const getCompletedPage = (count, offset) => {
        return GetGoalsByStatus(Shared.GoalType.Completed, count, offset);
    }

    const activegoals = activeGoals.map(goal => ActiveGoalItem(goal, "goal-container"));
    const pending = suggestedGoals.map(goal => GoalItem(goal, "goal-container"));


    return (
        <div className="goals">
            <div className="goals-container list content">
                <BackButton />
                <h1>My goals</h1>
                <p>Monitor your progress</p>
                <Section loading={loading} goals={activegoals} title="Active" />
                <Section loading={loading} goals={pending} title="Suggested" />
                <Paged title="Completed" empty={() => <span>You have no completed goals</span>} data={getCompletedPage} count={5} maxFunction={getCompletedMax} mapItems={goal => GoalItem(goal, "goal-container complete")} />
            </div>
            <div className="buttons">
                <Link to="/goals/suggest" className='button'>Suggest a new goal</Link>
                <Link to="/mystuff/howillgetthere" className='button button-tertiary'>How I'll get there</Link>
            </div>
        </div>
    )
}

export { Goals, SuggestGoal, GoalContext };