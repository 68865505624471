import { Outlet } from "react-router-dom";
import { ListConversationPage, ConversationPage, CreateConversation } from "./Messages";

const MessagesRoutes =
{
    path: "/messages",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <ListConversationPage />,
            children: []
        },
        {
            path: ":id",
            element: <ConversationPage />
        },
        {
            path: "create",
            element: <CreateConversation />
        }
    ]
}

export default MessagesRoutes;