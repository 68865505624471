import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import * as Messages from '../../shared/messages/Messages.js';
import { BuildConnection } from '../../shared/messages/Realtime.js';
import * as Data from './Data.js';
import { NotificationContext } from '../notifications/Notifications';
import { AuthContext } from "../auth/Auth";
import SearchBar from '../../shared/SearchBar.js';
import BackButton from '../../shared/navigation.js';
import { Loader } from '../../shared/layout/Layout.js';
import { ItemArea } from '../../shared/Events.js';
import * as Icon from '../../shared/Icons.js';
import Scrollbars from 'react-custom-scrollbars-2';
import './messages.scss';

function CreateConversation() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const user = auth.user();

    const [connection] = useState(BuildConnection(auth));

    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const practitionerId = user.practitionerId;
    const practitionerName = user.practitionerName.first;

    const changeSubject = (e) => {
        setSubject(e.target.value);
    }

    const changeMessage = (e) => {
        setMessage(e.target.value);
    }

    useEffect(() => {
        if (connection.state !== 'Disconnected') return;
        connection.start();
    }, [connection])

    const onSubmit = (values) => {
        const complete = (r) => {
            navigate("../" + r.topic.id);
        }

        if (connection.state !== 'Disconnected') {
            connection.invoke("SendConversation", { targetId: practitionerId, subject: values.subject, message: values.message})
                .then(complete);
        } else {
            Data.CreateConversation(practitionerId, {subject: values.subject, message: values.message})
                .then(complete);
        }
    }

    return (
        <div className='content-minimal'>
            <BackButton to="/messages" />
            <h1>Conversation with {practitionerName}</h1>
            <Messages.AddConversation onSubmit={onSubmit} changeSubject={changeSubject} subject={subject} changeMessage={changeMessage} message={message} />
        </div>
    )
}

const ListItem = (convo, assignedPractitioner) => {
    const unread = convo.unread ? "unread" : "";
    const old = convo.topic.practitionerId === assignedPractitioner ? "" : " old";
    const padlock = convo.topic.practitionerId === assignedPractitioner ? <></> : <div className='padlock-container'><Icon.Padlock /></div>;

    return (
        <li key={convo.topic.id} className={"conversation-list-item conversation-list-item-narrower clickable " + unread + old}>
            <Link to={convo.topic.id}>
                <div className='subject-body'>
                    <b>{convo.topic.subject} {padlock}</b>
                    <br />
                    <span className='preview'>
                        {convo.messages[0].content}
                    </span>
                </div>
                <div className='arrow-container'>
                    <Icon.FrontArrow alt="go to message" />
                </div>
                <span className='timestamp'>{Messages.BuildTimeStamp(convo.lastMessage)}</span>
            </Link>
        </li>
    )
}

function ListConversationPage() {
    const scrollRef = useRef(null);
    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));
    const user = auth.user();
    const assignedPractitioner = user.practitionerId;

    const [conversations, setConversations] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [search, setSearch] = useState("");

    const [loading, setLoading] = useState(true);
    const count = 5;    

    useEffect(() => {
            Data.GetConversations(10, 0).then(c => {
                setConversations(c);
                setLoading(false);

                if (connection.state !== 'Disconnected') return;
                connection.start();

                connection.on("ReceiveConversation", (conversation) => {
                    setConversations((cs) => {
                        conversation.unread = true;
                        return [conversation, ...cs];
                    });
                });

                connection.on("ReceiveMessage", (message) => {
                    setConversations((cs) => {
                        let convoIndex = cs.findIndex(c => c.topic.id === message.topicId);
                        let convo = cs[convoIndex];

                        if (convo === undefined)
                            return cs;

                        convo.messages = [message];
                        convo.unread = true;
                        convo.lastMessage = message.sent;

                        let update = [...cs];
                        update.splice(convoIndex, 1);
                        return [convo, ...update];
                    });
                });
            });
    }, [connection])

    useEffect(() => {
        if (search !== "" && connection.state === 'Connected') {
            connection.invoke("SearchConversation", search, undefined).then(c => {
                setFilteredConversations(c);
            })
        }
    }, [connection, search])


    let title = "Messages";
    let name = user.practitionerName.first;

    const displayConvosList = conversations.map(m => ListItem(m, assignedPractitioner));
    const displayConvos = displayConvosList.length === 0 ? Messages.NoMessage() : displayConvosList;

    const displayFilteredConvosList = filteredConversations.map(m => ListItem(m, assignedPractitioner));
    const displayFilteredConvos = displayFilteredConvosList.length === 0 ? Messages.NoResults() : displayFilteredConvosList;

    const configureDisplay = search ? displayFilteredConvos : displayConvos;

    const scroll = (convos) => {
        if (convos.length === 0) return;
        setConversations((existingConversations) => {
            const temp = [...existingConversations];
            temp.push(...convos);
            return temp;
        })
    }

    const onScroll = () => {
        if (scrollRef.current !== null) {
            const scrollBottom = scrollRef.current.getScrollHeight() - scrollRef.current.getClientHeight() - scrollRef.current.getScrollTop();
            if (Math.abs(scrollBottom) <= 1) {
                if (connection.state !== 'Connected') Data.GetConversations(count, conversations.length).then(scroll);
                else connection.invoke("GetConversations", null, count, conversations.length).then(scroll);
            }
        }
    }

    const display = loading
        ? <Loader />
        : <Scrollbars ref={scrollRef} onScroll={onScroll} className="conversation-list">
            <ul>
                {configureDisplay}
            </ul>
        </Scrollbars>;

    return (
        <div className='messages content-minimal'>
            <BackButton />
            <h1>{title}</h1>
            <SearchBar setSearch={setSearch} />
            <Messages.ListConversation name={name}>
                {display}
            </Messages.ListConversation>
        </div>
    )
}

function ConversationPage() {
    const auth = useContext(AuthContext)
    const notificationContext = useContext(NotificationContext);

    const [connection] = useState(BuildConnection(auth));

    const params = useParams();
    const id = params.id;

    const userId = auth.user().sub;

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Messages, id);
    }, [id, notificationContext]);

    const getConversation = (count, offset) => {
        return Data.GetConversation(id, count, offset);
    }

    const addMessage = (topicId, content) => {
        return Data.AddMessage(topicId, content);
    }
    
    return (
        <Messages.Conversation id={id} connection={connection} getConversation={getConversation} addMessage={addMessage} userId={userId} />
    )
}

export { ListConversationPage, ConversationPage, CreateConversation }