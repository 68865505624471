import api from '../../shared/api.js'

const GetNotifications = function(){
    return api.get("notifications");
}

const GetTotals = function(){
    return api.get("notifications/totals");
}

const ReadNotification = function(itemArea, itemId){
    return api.post("notifications/read", {
        itemArea,
        itemId
    });
}

const ReadAllNotifications = () => {
    return api.postOnly("notifications/read/all");
}

export { GetNotifications, GetTotals, ReadNotification, ReadAllNotifications };