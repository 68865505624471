import { useState, useEffect, useContext } from 'react';
import * as Data from './Data';
import { GetGoal, UpdateGoal } from '../Data'
import { GoalContext } from '../Goals'
import { GoalType } from '../../../shared/goals/Goals';
import * as SharedTasks from '../../../shared/goals/tasks/Tasks';
import './tasks.scss'
import BackButton from '../../../shared/navigation';
import { AnimationModal, LoaderPage } from '../../../shared/layout/Layout'
import { Link } from 'react-router-dom';
import { NotificationContext } from '../../notifications/Notifications';
import { ItemArea } from '../../../shared/Events';
import { Format } from '../../../shared/dates';
import { ModalContext } from '../../../shared/Modals';

const AddChildTask = () => {
    let goalId = useContext(GoalContext);
    const modalContext = useContext(ModalContext);
    const navigate = "/goals/" + goalId + "/tasks";
    const addTask = (task) => {
        return Data.CreateTask(goalId, task);
    }
    return <SharedTasks.Add header="Suggest a new task" addTask={addTask} navigate={navigate} text="Task suggested" modalContext={modalContext}/>
}

const EditChildTask = () => {
    let goalId = useContext(GoalContext);
    const navigate = "/goals/" + goalId + "/tasks";
    const getTask = (id) => {
        return Data.GetTask(goalId, id);
    }
    const deleteTask = (id) => {
        return Data.DeleteTask(goalId, id);
    }
    const editTask = (id, update) => {
        return Data.EditTask(goalId, id, update);
    }

    return <SharedTasks.Edit editTask={editTask} deleteTask={deleteTask} getTask={getTask} navigate={navigate} />
}

function PendingTask(task) {
    return (
        <label key={task.id} className="task-container">
            <input type="checkbox" disabled="disabled"></input>
            <b>{task.title}</b>
        </label>
    )
}

function CurrentTasks(task, disabled, setOpen, completeTask) {

    let complete = task.complete;

    const changeComplete = () => {
        const update = !complete;
        completeTask(update);
        setOpen(update);
    }

    return (
        <label key={task.id} className="task-container">
            <input type="checkbox" checked={complete} disabled={disabled} onChange={changeComplete} />
            <b>{task.title}</b>
        </label>
    )
}

function Tasks() {
    let goalId = useContext(GoalContext);
    const notificationContext = useContext(NotificationContext);

    let [goal, setGoal] = useState(undefined);
    let [tasks, setTasks] = useState([]);
    let [pending, setPending] = useState([]);
    let [open, setOpen] = useState(false);
    let [animation, setAnimation] = useState("taskCompleted");
    let [animationText, setAnimationText] = useState("Task completed")

    useEffect(() => {
        (async () => {
            const g = await GetGoal(goalId);
            const data = await Data.GetTasks(goalId);
            let pendingTasks = data.filter(task => task.status === SharedTasks.TaskType.Pending).sort((a, b) => parseInt(a.order) - parseInt(b.order));
            let activeTasks = data.filter(task => task.status === SharedTasks.TaskType.Approved).sort((a, b) => parseInt(a.order) - parseInt(b.order));
            setTasks(activeTasks);
            setPending(pendingTasks);
            setGoal(g);
        })();
    }, [goalId]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Tasks);
    }, [notificationContext]);

    const completedChecker = (tasks) => {
        const completedCheck = tasks.every((task) => task.complete === true);
        if (completedCheck === true) {
            setAnimation("goalCompleted");
            setAnimationText("Goal completed");
            UpdateGoal(goalId, 3);
        }
        else {
            setAnimation("taskCompleted");
            setAnimationText("Task completed");
        }
    }

    let mapTasks = tasks.map((task) => {
        let disabled = ([GoalType.Completed, GoalType.Rejected].indexOf(goal.status) >= 0) ? "disabled" : "";
        return CurrentTasks(task, disabled, setOpen, (complete) => {
            (async () => {
                await Data.EditTask(task.goalId, task.id, {
                    title: task.title,
                    complete: complete
                });
            })();

            const newList = tasks.map((item) => {
                if (item.id === task.id) {
                    const updateItem = {
                        ...item,
                        complete: !item.complete,
                    };

                    return updateItem;
                }

                return item;
            });
            completedChecker(newList);
            setTasks(newList);
        });
    })

    let mapPending = pending.map(PendingTask);
    let allTasks = tasks.length === 0 ? <div>You have no active tasks</div> : mapTasks;
    let pendingTasks = pending.length === 0 ? <div>You have no suggested tasks</div> : mapPending;

    if (goal === undefined) {
        return (
            <LoaderPage />
        )
    }

    const addButton = ([GoalType.Pending, GoalType.Approved].indexOf(goal.status) >= 0) ? <Link to="add" className='button'>Suggest a new task</Link> : null;

    return (
        <div className='tasks'>
            <div className="tasks-container list content">
                <div>
                    <BackButton to="/goals" />
                    <h1>{goal.title}</h1>
                </div>
                <span className='date'>Due: {Format(goal.deadline).dayYear}</span>
                <p>{goal.description}</p>
                <h2>Suggested tasks</h2>
                <div className="list">
                    {pendingTasks}
                </div>
                <h2>Active tasks</h2>
                <AnimationModal animation={animation} open={open} setOpen={setOpen} text={animationText} />
                <div className="list">
                    {allTasks}
                </div>
            </div>
            <div className="buttons">
                {addButton}
            </div>
        </div>
    )
}
export { Tasks, AddChildTask, EditChildTask };