import { useEffect, useState, createContext, useContext } from "react";
import { GetNotifications, GetTotals, ReadAllNotifications, ReadNotification } from "./Data";
import './notifications.scss';
import { ItemLink, ItemArea } from "../../shared/Events";
import { IconButton, Loader } from "../../shared/layout/Layout";
import * as Icon from "../../shared/Icons";
import BackButton from "../../shared/navigation";

const NotificationItem = function (props){
    const n = props.item;

    return (
        <li>
            <ItemLink item={n} child={true}>
                <p className="text">{n.content}</p>
            </ItemLink>
        </li>
    )
}

const Notifications = function (props) {
    const [notifications, setNotifications] = useState([]);
    const noteContext = useContext(NotificationContext);
    const [loading, setLoading] = useState(true);
    const notes = [];

    useEffect(() => {
        GetNotifications().then(n => {
            setNotifications(n);
            setLoading(false);
        })
    }, []);

    const iterationCount = (props.count && props.count <= notifications.length) ? props.count : notifications.length;
    
    if (notifications.length > 0) {
        for (let x = 0; x < iterationCount; x++) {
            const n = notifications[x];
            notes.push(<NotificationItem key={n.id} item={n} />)
        }
    }

    const clearNotifications = () => {
        ReadAllNotifications().then(() => noteContext.refresh());
        setNotifications([]);
    }

    const clearButton = (notifications === undefined || notifications.length === 0)
    ? <></> 
    : <IconButton icon={<Icon.ClearNotifications />} submit={clearNotifications} className="button-secondary">Clear notifications</IconButton>;

    if (props.count) {
        return (
            <ul className="events-list">
                {loading ? <Loader /> : notes}
            </ul>
        )
    }

    else {
        return (
            <div className="content notifications">
                <BackButton />
                <h1>Notifications</h1>
                <div className="button-container">
                    {clearButton}
                </div>
                <ul className="events-list">
                {loading ? <Loader /> : notes.length === 0 ? <p>You have no new notifications.</p> : notes}
                </ul>
            </div>
        )
    }
}

const NotificationContext = createContext({
    refresh: () => {},
    readNotification: (itemArea, itemId) => {
        // refresh navigation
        // send message
    }
});


const notificationData = "notification-data";

const ReadNotificationItem = (itemArea, itemId) => {
    var data = sessionStorage.getItem(notificationData);

    if (data === undefined || data === "") {
        return ReadNotification(itemArea, itemId);
    }

    const current = JSON.parse(data);
    var send = false;
    switch (itemArea){
        case ItemArea.Messages:
            send = current.Messages !== 0;
            break;
        case ItemArea.Goals:
            send = current.Goals !== 0;
            break;
        case ItemArea.Tasks:
            send = current.Tasks !== 0;
            break;
        case ItemArea.Stuff:
            send = current.Stuff !== 0;
            break;
        case ItemArea.Appointments:
            send = current.Appointments !== 0;
            break;
        case ItemArea.Settings:
            send = current.Settings !== 0;
            break;
        case ItemArea.Cases:
            send = current.Cases !== 0;
            break;
        default:
            break;
    }

    if(send){
        return ReadNotification(itemArea, itemId);
    }

    return new Promise(() => { return [] }, () => { });

}

const GetNotificationTotals = () => {
    return GetTotals().then(t => {
        sessionStorage.setItem(notificationData, JSON.stringify(t));
        return t;
    });
}

const ShowNotificationTotal = () => {
    const [count, setCount] = useState(0);
    const [style, setStyle] = useState("navBubble");

    useEffect(() => {
        GetTotals().then((n) => {
            setCount(n.Messages + n.Goals + n.Tasks + n.Stuff + n.Appointments + n.Cases);
        });
    }, [])

    
    if (count > 9) {
        setCount("9⁺");
        setStyle("navBubble navBubble-greater");
    };

    let notificationBubble = count === 0 ? null : <span className={style}>{count}</span>
    
    return (
        <>
            {notificationBubble}
        </>
    )
}

export { Notifications, GetNotificationTotals, ReadNotificationItem, NotificationContext, ShowNotificationTotal }