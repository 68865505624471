import api from '../../shared/api.js'

const GetMyStuff = function (group, count, offset) {
    return api.getPaged("mystuff/" + group, count, offset);
}

const SubmitStuff = function(stuff) {
    return api.post("mystuff/" + stuff.Group + "/" + stuff.Type,  stuff);
}

const UploadImage = function(group, image) {
    return api.formJson("mystuff/" + group + "/upload", image);
}

const DeleteStuff = function(ids) {
    return api.postOnly("mystuff/stuff/delete", ids);
}

export { GetMyStuff, SubmitStuff, UploadImage, DeleteStuff };