import { Link } from "react-router-dom";
import BackButton from "../../shared/navigation";

const AboutTheApp = (props) => {
    return (
        <div className="content">
            <BackButton />
            <h1>About the Connect app</h1>
            <p>
                The Connect app is a tool to help you communicate with your worker.
                <br /> <br />
                Your worker will show you how to use it.
                <br /> <br />
                We hope you find it useful.
            </p>
        </div>
    );
}

const TermsContent = () => {
    return (
        <>
        <h2>Our rules</h2>
        <ul><li>You must keep your password private. You must not let anyone else into your Connect account.</li>
            <li>You agree to notify your worker immediately if you believe that your login or password has been, or may be used, without your permission.</li>
            <li>You must use the app only for lawful purposes.</li>
            <li>You must not interfere with, damage or disrupt any part of the app.</li>
            <li>You pay for the data you use while using the app.</li>
        </ul>
        <h2>Your rights</h2>
        <ul><li>You own any photos and contributions you upload to the app, but YOT has the right to use them in the process of our work with you. </li>
            <li>You may ask for any content to be removed from the app.</li>
        </ul>
        <h2>Our rights</h2>
        <ul><li>We may withdraw your account if you break the rules.</li>
            <li>We may remove any content you upload which we believe is illegal, abusive or does not respect the privacy of someone else.</li>
            <li>We have the right to use the content you share in the process of our work with you. This will remain between you and your YOT worker.</li>
            <li>The content you share will not be shared with anyone else without your permission unless the material represents or identifies a risk to yourself or others. In that case material may need to be shared to keep people safe.</li>
        </ul>
        </>
    )
}

const TermsOfUse = (props) => {
    return (
        <div className='content legal'>
            <BackButton />
            <h1>Terms of use</h1>
            <p>Please read these terms of use carefully before you start to use the Connect app.</p>
            <TermsContent />
            <p>Visit our <Link to="/settings/privacy">Privacy policy</Link> for additional information on how we may use and process your information and content.
                <br />
                <br />
                Last updated: 7 November 2022.
            </p>
        </div>
    );
}

const PrivacyAndCookies = (props) => {
    return (
        <div className="content legal">
            <BackButton />
            <h1>Privacy policy and cookies</h1>
            <h2>Privacy policy</h2>
            <p>
                This privacy policy includes how we use or share your information.
                <br /> <br />
                Medway Council collects, uses, and is responsible for certain personal information about you. When we do so we're regulated under the General Data Protection Regulation. When we use your personal data, Medway Council is what we call the data controller.
                <br /> <br />
                As the data controller, we must:
            </p>
            <ul>
                <li>only keep your data that we need to provide services and do what the law says we must</li>
                <li>keep your records safe and accurate</li>
                <li>only keep your data for as long as we have to</li>
                <li>collect, store, and use your data in a way that does not break any data protection laws.</li>
            </ul>
            <h2>What personal data is</h2>
            <p>Personal data is information about a person that means we can work out who they are. Some data is called special category data which is more sensitive, and we must look after it more carefully. This includes details of ethnic origin, religious beliefs, sexual orientation, and health data.</p>
            <h2>Personal data collected by the Connect app</h2>
            <p>While providing advice and support for you the Connect app collects the following personal information when you provide it to us:</p>
            <ul>
                <li>your name and contact details</li>
                <li>links, photos, emojis and other content you share while using the app</li>
                <li>reasons for support such as what is working well and what you're worried about</li>
                <li>information such as further details of your issues and challenges, and how we are going to work together to bring about the changes you want to see.</li>
            </ul>
            <h2>Why we can use your data</h2>
            <p>We can use your data if it is a legal obligation or public task under various UK laws including but not limited to:</p>
            <ul>
                <li>The Crime and Disorder Act 1998</li>
                <li>The Criminal Justice Act 2013</li>
                <li>The Children Act 1989, 2004.</li>
            </ul>
            <p>Local Safeguarding Children Boards Regulations 2006(SI 2006/90) will also use your data under UK laws such as:</p>
            <ul>
                <li>The Localism Act 2011</li>
                <li>The Equality Act 2010.</li>
            </ul>
            <h2>How we use your personal information</h2>
            <p>We use your personal information to:</p>
            <ul>
                <li>enable us to work with other teams and organisations to ensure you receive the right support at the right time</li>
                <li>plan and provide the most appropriate level of support to you and your family</li>
                <li>undertake our statutory duties in supporting children</li>
                <li>evaluate and quality assure the services we provide</li>
                <li>inform future service provision and the commissioning of services</li>
                <li>monitor equality</li>
                <li>prevent and detect crime.</li>
            </ul>
            <h2>Who we can share your data with</h2>
            <p>We can share your data with the following services, partners and organisations if necessary:</p>
            <ul>
                <li>Medway Council departments working to improve outcomes for children and children</li>
                <li>providers of local council services such as Family Solutions, young carers, mental health and education services</li>
                <li>partner agencies signed up to the Kent and Medway Information Sharing Agreement</li>
                <li>government departments including the Ministry of Justice, Department for Education, and HM Prison and Probation Service</li>
                <li>Ofsted and Her Majesty's Inspectorate of Probation (HMIP) if children's services or youth justice services are inspected</li>
                <li>other youth justice services.</li>
            </ul>
            <p>We will share personal information with law enforcement or other authorities if needed by applicable law.</p>
            <h2>Your rights</h2>
            <p>
                We will hold your personal information securely and retain it until you reach the age of 25, after which the information is archived or securely destroyed.
                <br /> <br />
                You can ask to see what data we hold about you and ask to be sent a copy. This is called a subject access request (SAR).
                <br /> <br />
                A SAR is free unless there's a lot of work or a lot of photocopying and postage, which we are allowed to charge a reasonable fee for.
                <br /> <br />
                You can also ask us to:
            </p>
            <ul>
                <li>correct your data if you think it is wrong</li>
                <li>stop using your data if you think we no longer should be using it</li>
                <li>stop using your data if you think it is wrong, until it's put right</li>
                <li>ask that no automated processing takes place with your data</li>
                <li>ask for any automated portable electronic data file we hold about you to be sent to another organisation</li>
                <li>consider any complaint you have about how we have used your data.</li>
            </ul>
            <p>We will always seek to comply with your request, however we may need to hold or use your information to comply with legal duties. Your request may delay or prevent us from delivering a service to you.</p>
            <h2>Keeping your personal information safe</h2>
            <p>We have security measures in place to prevent personal information from being accidently lost or accessed in an unauthorised way. We limit access to your personal information to those who have a genuine business need to know it.</p>
            <h2>Ask a question or make a complaint</h2>
            <p>
                If you want to ask us anything about data protection or make a complaint about how we have used or looked after your data, you may contact our Data Protection Officer at:
                <br /> <br />
                Data Protection Officer<br />
                Information Governance Team.
                <br />
                Legal Services
                <br />
                Medway Council
                <br />
                Gun Wharf
                <br />
                Dock Road
                <br />
                Chatham
                <br />
                Kent
                <br />
                ME4 4TR
                <br /> <br />
                Email <a href="mailto:gdpr@medway.gov.uk">gdpr@medway.gov.uk</a>
            </p>
            <h2>Cookies and app data storage</h2>
            <p>
                The Connect app does not use any cookies.
                <br /> <br />
                Some data is stored by your browser for the site to operate:
            </p>
            <h3>Session storage</h3>
            <p>This data is cleared when you log out:</p>
            <ul>
                <li>"jwt" contains the information we need to show you your data</li>
                <li>"notification-data" contains how many notifications you have.</li>
            </ul>
            <h3>Local storage</h3>
            <p>This data is saved between sessions:</p>
            <ul>
                <li>"theme" is your choice of light or dark theme</li>
                <li>"background" is your background image if you've chosen one</li>
                <li>"emoji-mart" is which emoji you've used.</li>
            </ul>
        </div>
    )
}

const Accessibility = (props) => {
    return (
        <div className="content legal">
            <BackButton />
            <h1>Accessibility statement for Connect app</h1>
            <p>
                This accessibility statement applies to the Connect app, run by Medway Council.
                <br /> <br />
                Making something accessible means that it is designed to be used by as many people as possible. For example, that means you should be able to:
            </p>
            <ul>
                <li>zoom in up to 300% without the text spilling off the screen</li>
                <li>navigate most of the web app using just a keyboard</li>
                <li>navigate most of the web app using speech recognition software</li>
                <li>listen to most of the web app using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver).</li>
            </ul>
            <p>
                We've also made the text as simple as possible to understand.
                <br /> <br />
                <a href="https://mcmw.abilitynet.org.uk/">AbilityNet</a> has advice on making your device easier to use if you have a disability.
            </p>
            <h2>How accessible this web app is</h2>
            <p>Parts of this app may not be fully accessible:</p>
            <ul>
                <li>links you or your worker add to external sites</li>
                <li>content you or your worker add.</li>
            </ul>
            <h2>Feedback and contact information</h2>
            <p>If you need information on this app in a different format you should contact your worker.</p>
            <h2>Reporting accessibility problems with this app</h2>
            <p>
                We're always looking for ways to improve the accessibility of this app.
                <br /> <br />
                If you find any problems that are not listed on this page or think we're not meeting your accessibility requirements, you should contact your worker.
            </p>
            <h2>Enforcement procedure</h2>
            <p>
                The Equality and Human Rights Commission (EHRC) is responsible for making sure that Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the 'accessibility regulations') are followed.
                <br /> <br />
                If you contact us with a complaint and you're not happy with our response, contact the <a href="https://www.equalityadvisoryservice.com">Equality Advisory and Support Service</a>.
            </p>
            <h2>Technical information about this app's accessibility</h2>
            <p>Medway Council are committed to making this app accessible, in line with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018. </p>
            <h2>Compliance status</h2>
            <p>Other than for the items listed below, this web app must follow the <a href="https://www.w3.org/TR/WCAG21/">Web Content Accessibility Guidelines version 2.1</a>.</p>
            <h2>Non-accessible content</h2>
            <h3>Disproportionate burden</h3>
            <p>Not applicable.</p>
            <h3>Content that's not within the scope of the accessibility regulations</h3>
            <h4>Links to external sites</h4>
            <p>You or your worker might link to content that is not in an accessible format. This could mean that:</p>
            <ul>
                <li>titles automatically created for links may not describe the link very well</li>
                <li>automatic image generation from links may show inaccessible images. </li>
            </ul>
            <p>We will not check external content for accessibility.</p>
            <h4>Content you or your worker add</h4>
            <p>Content you or your worker add to 'My stuff' may not be in an accessible format.</p>
            <p>This is because app users can choose what title to give an image they share on the app. The title they choose may not adequately describe the image to someone who cannot see it.</p>
            <p>We will not check that content added by users is accessible.</p>
            <h2>Preparation of this accessibility statement</h2>
            <p>This statement was prepared on 7 November 2022. It was last reviewed on 7 November 2022.</p>
            <p>This app was last tested for compliance with the Web Content Accessibility Guidelines (version 2.1) level AA on 13 October. The test was carried out by Medway Council using manual tests and Axe for Edge.</p>
        </div>
    )
}

export { AboutTheApp, TermsOfUse, PrivacyAndCookies, Accessibility, TermsContent };