import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as MyStuff from '../mystuff/Homepage';
import { AuthContext } from '../auth/Auth';
import { GetBackground } from '../settings/Data';
import './homepage.scss'
import { Notifications, ShowNotificationTotal } from '../notifications/Notifications';
import ProfileHeader from '../../shared/ProfileHeader';
import { NickNameGenerator } from '../../shared/layout/Layout';

function Homepage() {
    const authContext = useContext(AuthContext);
    const user = authContext.user()
    const name = user.name;

    const practitionerName = user.practitionerName;

    const [backgroundDisplay, setBackgroundDisplay] = useState();

    useEffect(() => {

        let background = localStorage.getItem("background");
        if (background === null) {
            GetBackground().then(serverBackground => {
                if (serverBackground.background !== null) {
                    localStorage.setItem("background", serverBackground.background.toUpperCase());
                    setBackgroundDisplay(serverBackground.background);
                }
                else {
                    localStorage.setItem("background", "none");
                }
            });
        }
        else if (background !== "none") {
            setBackgroundDisplay(background);
        }
    }, [])

    const profileUrl = "child/profile-picture?t=" + Math.floor(Math.random() * 100);

    return (
        <div className='homepage'>
            <ProfileHeader backgroundImage={backgroundDisplay} profileUrl={profileUrl}/>
            <div className="content-homepage homepage-content">
                <div className="user-name">
                    <span className="info">
                        <b>{name.first} {NickNameGenerator(name.nickname)} {name.last}</b>
                    </span>
                </div>
                <div className="stuffbox-container">
                    <h2 className="main-headers" >My stuff</h2>
                    <MyStuff.WhoIAm />
                    <MyStuff.WantToBe />
                    <MyStuff.HowGetThere />
                    <MyStuff.AddMoreStuff />
                </div>
                <div className='timeline-container'>
                    <Link className="button button-secondary" to="/timeline">View my timeline</Link>
                </div>
                <div className="notification-container" >
                    <div className="notification-header-container">
                        <h2>Notifications</h2>
                        <ShowNotificationTotal />
                    </div>
                    <Notifications count={5} />
                </div>
                <div className='homepage-button-container'>
                    <Link className="button" to="/notifications">View all notifications</Link>
                    <Link className="button button-square button-tertiary" to={"/practitioner"}>
                        Your practitioner is {practitionerName.first} {practitionerName.last}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Homepage;