import React from "react";
import Homepage from './homepage/Homepage';
import { Logout } from './auth/Auth';
import AppointmentsRoutes from './appointments/Routes';
import SettingsRoutes from './settings/Routes';
import MessagesRoutes from "./messages/Routes";
import MyStuffRoutes from "./mystuff/Routes";
import GoalsRoutes from "./goals/Routes";
import NotificationsRoutes from "./notifications/Routes";
import TimelineRoutes from "./timeline/Routes";
import NotFound from "./homepage/NotFound";
import { LoadPage, StylePage } from "../shared/layout/style/Style";
import PractitionerRoutes from "./practitioner/Routes";

const ROUTES = [
    {
        path: "/",
        element: <Homepage />,
        children: []
    },
    {
        path: "/logout",
        element: <Logout />,
        children: []
    },
    AppointmentsRoutes,
    SettingsRoutes,
    MessagesRoutes,
    MyStuffRoutes,
    GoalsRoutes,
    NotificationsRoutes,
    TimelineRoutes,
    PractitionerRoutes,
    {
        path: "*",
        element: <NotFound/>,
        children:[]
    },
    {
        path: "/style",
        element: <StylePage />,
        children:[]
    },
    {
        path: "/style/loader",
        element: <LoadPage />,
        children:[]
    }
];

export default ROUTES;
