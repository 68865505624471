import api from '../../shared/api.js'

const GetTimeline = function (count, offset, userId) {
    if (offset === undefined) {
        if (userId === undefined || userId === null) {
            return api.get("timeline");
        }
        return api.get("timeline/?userId=" + userId);
    }

    if (userId === undefined || userId === null) {
        return api.getPaged("timeline", count, offset);
    }
    return api.getPaged("timeline/?userId=" + userId, count, offset);
}

const GetMaxTimeline = function (userId) {
    if (userId === undefined || userId === null) {
        return api.get("timeline/max");
    }
    return api.get("timeline/max?userId=" + userId);
}

export { GetTimeline, GetMaxTimeline };