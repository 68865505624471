import api from '../../shared/api.js'

const GetAppointments = function (count, offset) {
    if(offset === undefined)
        return api.get("appointments");

    return api.getPaged("appointments", count, offset);
}

const GetNextAppointment = function () {
    return api.get("appointments/next");
}

const GetMaxAppointments = function(){
    return api.get("appointments/max");
}

export { GetAppointments, GetNextAppointment, GetMaxAppointments };