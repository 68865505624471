import React, { useContext, useEffect, useState } from 'react';
import { GetAppointments, GetNextAppointment, GetMaxAppointments } from './Data';
import './Appointments.scss'
import { Pager } from '../../shared/layout/Layout';
import { NotificationContext } from '../notifications/Notifications';
import { ItemArea } from '../../shared/Events';
import { Format } from '../../shared/dates';
import BackButton from '../../shared/navigation';

function Next(next) {
    return (
        <div className="next">
            <p>
                Your next appointment is
            </p>
            <h2 className='next-date'>{Format(next.occurs).dayYear}</h2>
            <p>
                {Format(next.occurs).time} {next.location}
            </p>
        </div>
    );
}

function None() {
    return (
        <div className='next'>
            <p>You have no appointments</p>
        </div>
    )
}

function Upcoming(apt) {
    return (
        <li key={apt.id}>
            {Format(apt.occurs).dayYearTime} {apt.location}
        </li>)
}

function NoUpcoming() {
    return (
        <li>
            No appointments
        </li>
    )
}


function Appointments() {
    const notificationContext = useContext(NotificationContext);
    const [nextAppointment, setNextAppointment] = useState(undefined);

    useEffect(() => {
        GetNextAppointment().then(n => setNextAppointment(n));
    },[])

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Appointments);
    }, [notificationContext]);

    const nextComponent = nextAppointment === undefined ? None : Next

    return (
        <div className='appointments content'>
            <BackButton />
            <h1>Appointments</h1>
            {nextComponent(nextAppointment)}
            <hr />
            <h3>Upcoming appointments</h3>
            <Pager maxFunction={GetMaxAppointments} data={GetAppointments} count={5} empty={NoUpcoming} mapItems={Upcoming} className="upcoming" />
        </div>
    );
}

export { Appointments };