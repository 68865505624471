import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { GetTheme } from "./settings/Data";
import { AuthContext, BuildContext } from "./auth/Auth";
import { Navigation } from './navigation/Navigation';
import { NotificationContext, ReadNotificationItem } from './notifications/Notifications';
import ROUTES from "./routes";
import ANONROUTES from "./auth/routes";
import '../shared/layout/components/themes.scss';
import Terms from "./settings/Terms";
import { ModalContext, UpdateModal } from "../shared/Modals";

function App() {
  const [refresh, setRefresh] = useState(0);
  const [terms, setTerms] = useState(false);
  const router = useRoutes(ROUTES);
  const anonRouter = useRoutes(ANONROUTES);

  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [navigate, setNavigate] = useState("");

  const theme = localStorage.getItem("theme");
  const jwt = sessionStorage.getItem("jwt");
  let authProvider = undefined;

  useEffect(()=>{
    if(authProvider !== undefined && authProvider.user().accepted === true){
      setTerms(true);
  }
  },[authProvider]);

  if (jwt === null) {
    if (theme !== null) {
      document.documentElement.className = theme;
    } else {
      document.documentElement.className = "light";
    }
    return (
      <div className="app">
        {anonRouter}
      </div>
    )
  }

  authProvider = BuildContext(jwt);

  if (theme !== null) {
    document.documentElement.className = theme;
  } else {
    GetTheme().then(serverTheme => {
      localStorage.setItem("theme", serverTheme);
      document.documentElement.className = serverTheme;
    });
  }

  const notificationProvider = {
    readNotification: (itemArea, itemId) => {
      ReadNotificationItem(itemArea, itemId).then((r) => {
        if (r.length !== 0) setRefresh(refresh + 1);
      });
    },
    refresh:() => {setRefresh(refresh + 1)}
  }

  if(terms === false){
    return (
      <div className="app">
        <div className="app-container">
          <Terms setTerms={setTerms} />
        </div>
      </div>
    );
  }

  const setShowTextNavigate = (text, navigate) => {
    setText(text);
    setShow(true);
    if (navigate) setNavigate(navigate);
  }

  return (
    <div className="app">
      <AuthContext.Provider value={authProvider}>
        <NotificationContext.Provider value={notificationProvider}>
          <Navigation refresh={refresh} />
          <ModalContext.Provider value={{
            setShowTextNavigate: (text, navigate) => { setShowTextNavigate(text, navigate) }
          }}>
            <div className="app-container">
              {router}
              <UpdateModal show={show} setShow={setShow} text={text} navigate={navigate} />
            </div>
          </ModalContext.Provider>
        </NotificationContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
