import api from '../../../shared/api'

const GetTasks = function (goalId) {
    return api.get("goals/" + goalId + "/tasks");
}

const GetTask = function (goalId, id) {
    return api.get("goals/" + goalId + "/tasks/" + id);
}

const CreateTask = function (goalId, task){
    return api.post("goals/" + goalId + "/tasks", task);
}

const EditTask = function (goalId, id, update) {
    return api.put("goals/" + goalId + "/tasks/" + id, update)
}

const DeleteTask = function (goalId, id) {
    return api.delete("goals/" + goalId + "/tasks/" + id)
}

export { GetTasks, GetTask, CreateTask, EditTask, DeleteTask };