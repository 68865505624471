import { useState, useContext } from 'react';
import { GetMaxTimeline, GetTimeline } from './Data';
import { AuthContext } from '../auth/Auth';
import { Pager } from '../../shared/layout/Layout';
import './timeline.scss'
import { Activities, NoActivities } from '../../shared/timeline/Timeline';
import BackButton from '../../shared/navigation';

const Timeline = function (props) {
    const [filterId, setFilterId] = useState(null);
    var authContext = useContext(AuthContext);
    // practitioner id + name

    let changeFilter = (e) => {
        switch (e.target.value) {
            case "0":
                setFilterId(null);
                break;
            case "1":
                setFilterId(authContext.user().sub);
                break;
            default:
                setFilterId(null);
                break;
        }
    }

    let getTimeline;
    let getMaxTimeline;

    if (filterId) {
        getTimeline = (count, page) => GetTimeline(count, page, filterId);
        getMaxTimeline = () => GetMaxTimeline(filterId);
    }
    else {
        getTimeline = (count, page) => GetTimeline(count, page);
        getMaxTimeline = () => GetMaxTimeline();
    }
    
    return (
        <div className="content timeline">
            <BackButton />
            <h1>My timeline</h1>
            <select onChange={changeFilter}>
                <option value="0">All activity</option>
                <option value="1">My activity</option>
            </select>
            <Pager maxFunction={getMaxTimeline} data={getTimeline} count={5} empty={NoActivities} mapItems={Activities} className="events-list" />
        </div>
    )
}

export default Timeline;