import api from '../../shared/api.js';

const GetConversations = function (count, offset) {
    if(offset === undefined)
        return api.get("conversations");
    
    return api.getPaged("conversations", count, offset);
}

const GetConversation = function (id, pageSize, offset) {
    if(offset === undefined)
        return api.get("conversations/" + id);

    return api.getPaged("conversations/" + id, pageSize, offset);
}

const CreateConversation = function (practitionerId, subject, message) {
    return api.post("conversations" , { targetId: practitionerId, subject, message });
}

const AddMessage = function (id, message) {
    return api.post("conversations/" + id, { content: message });
}

export { GetConversations, GetConversation, CreateConversation, AddMessage }