import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Settings } from '../settings/Settings';
import { AuthContext } from '../auth/Auth';
import { GetNotificationTotals } from '../notifications/Notifications';
import { BuildConnection } from '../../shared/notifications/Realtime';
import './navigation.scss'
import * as Icon from '../../shared/Icons';
import { GetPathFragment } from '../../shared/layout/Layout';
import SetAuthContext from '../../shared/auth/Auth';
import { Resendtoken } from '../../shared/auth/Data';


function Notification(props) {
    let count = props.count;
    
    let notifClass = "badge";
    if (count > 9) {
        count = "9⁺"
        notifClass = "badge badge-greater"
    };

    let withNotif = (
    <div className="notifContainer">
        {props.children}
        <span className={notifClass}>{count}</span>
    </div>);

    let withoutNotif = (
    <div className="notifContainer">
        {props.children}
    </div>);

    return props.count > 0 ? withNotif : withoutNotif
}

function Messages(props) {
    return (
        <Link to="messages" className="item" aria-label="Messages">
            <Notification count={props.count}>
                <Icon.Messages />
            </Notification>
            <span className={props.navClass}>Messages</span>
        </Link>
    )
}

function Appointments(props) {
    return (
        <Link to="appointments" className="item" aria-label="Appointments">
            <Notification count={props.count}>
                <Icon.Appointments />
            </Notification>
            <span className={props.navClass}>Appointments</span>
        </Link>
    )
}

function MyStuff(props) {
    return (
        <Link to="mystuff" className="item" aria-label="MyStuff">
            <Notification count={props.count}>
                <Icon.Briefcase alt="my stuff button" aria="MyStuff"/>
            </Notification>
            <span className={props.navClass}>My stuff</span>
        </Link>
    )
}

function MyGoals(props) {
    return (
        <Link to="goals" className="item" aria-label="Goals">
            <Notification count={props.count}>
                <Icon.Goals />
            </Notification>
            <span className={props.navClass}>My goals</span>
        </Link>
    )
}

function Home() {
    return (
        <Link to="/" className="item" aria-label="Home">
            <Icon.Home />
        </Link>
    )
}

function NotificationBell(props) {
    return (
        <Link to="notifications" className="item" aria-label="notifications">
            <Notification count={props.count}>
                <div className={props.navClass}>
                    <Icon.Notification />
                </div>
            </Notification>
        </Link>
    )
}

function NavMenu(props) {
    const pathFragment = GetPathFragment();

    let styles = { messages: "text", appointments: "text", mystuff: "text", goals: "text" };

    const data = props.data;

    let msgNotif = 0;
    let appNotif = 0;
    let stuffNotif = 0;
    let goalsNotif = 0;

    if (data !== undefined) {
        msgNotif = data.Messages;
        goalsNotif = data.Goals + data.Tasks;
        stuffNotif = data.Stuff;
        appNotif = data.Appointments;
    }

    styles[pathFragment] += " focus";

    let navItems = (
        <>
            <Messages count={msgNotif} navClass={styles.messages} />
            <Appointments count={appNotif} navClass={styles.appointments} />
            <MyStuff count={stuffNotif} navClass={styles.mystuff} />
            <MyGoals count={goalsNotif} navClass={styles.goals} />
        </>
    )

    return (
        <nav className="nav-menu">
            {navItems}
        </nav>
    )
}

function NavigationHeader(props) {
    const data = props.data;
    const location = useLocation();
    const pathFragment = GetPathFragment();

    let styles = { notifications: "", settings: "" };

    let homeLink = null;
    if(location.pathname !== "/") {
        homeLink = <Home />;
        styles[pathFragment] = "focus";
    }

    let notifications = 0;
    if (data !== undefined) {
        notifications = data.Messages + data.Goals + data.Tasks + data.Stuff + data.Appointments + data.Cases;
    }

    return (
        <div className="header">
            <NotificationBell count={notifications} navClass={styles.notifications}/>
            {homeLink}
            <Settings navClass={styles.settings}/>
        </div>
    )
}

const Navigation = function (props) {
    const auth = useContext(AuthContext); 
    const [connection] = useState(BuildConnection(auth));

    const [data, setData] = useState(undefined);
    const refresh = 60 * 1000;  // x seconds

    useEffect(()=> {
        GetNotificationTotals().then(t => setData(t));
    }, [props.refresh]);

    useEffect(() => {
        let id = 0;
        if (connection.state === 'Disconnected'){
            connection.start();
        }

        connection.on("SendNotification", (notification, area) => {
            // alert with the notification somehow
            if (notification.itemType === 24) {
                Resendtoken(auth.user().username).then((r) => {
                    SetAuthContext(r);
                });
            }
            setData(old => {
                let update = {...old};
                update[area] += 1;
                return update;
            });
        });

        let update = () => {
            if(connection.state === 'Connected'){
                connection.invoke("GetTotals").then(totals => {
                    setData(totals);
                    sessionStorage.setItem("notification-data", JSON.stringify(totals));
                });  
            }
            id = setTimeout(update, refresh);
        }
        id = setTimeout(update, refresh);
        
        return () => {
            clearTimeout(id);
            connection.off("SendNotification");
        };
    }, [connection, refresh, auth]);

    return (
        <>
        <NavigationHeader data={data} />
        <NavMenu data={data} />
        </>
    )
}

export { Navigation };