import api from '../../shared/api'

const GetGoals = function () {
    return api.get("goals");
}

const GetGoal = function (id) {
    return api.get("goals/" + id);
}

const GetGoalsByStatus = function (status, count, offset) {
    return api.getPaged("goals/status/" + status, count, offset);
}

const GetGoalsMaxByStatus = function (status) {
    return api.get("goals/status/" + status + "/max");
}

const CreateGoal = function (goal) {
    return api.post("goals", goal);
}

const EditGoal = function (id, update) {
    return api.put("goals/" + id, update)
}

const DeleteGoal = function (id) {
    return api.delete("goals/" + id);
}

const UpdateGoal = function (id, status) {
    return api.put("goals/" + id + "/status?status=" + status);
}

export { GetGoals, GetGoalsByStatus, GetGoalsMaxByStatus, GetGoal, CreateGoal, EditGoal, DeleteGoal, UpdateGoal };