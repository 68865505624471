import { Outlet } from "react-router-dom";
import { MyStuff } from "./Homepage";
import { AddGroupStuffPage, AddStuffPage, MyStuffPage } from "./MyStuff";
import { Link, Emoji, StuffGroup, Image, Text } from "../../shared/mystuff/Stuff";
import { AddText, AddLink, AddEmoji, AddImage } from "../../shared/mystuff/AddStuff";
import { SubmitStuff, UploadImage } from "./Data"; 

const submitLink = (link) => {
    var newLink = new Link("00000000-0000-0000-0000-000000000000", link.group, link.title, link.ref);
    return SubmitStuff(newLink);
}
const submitEmoji = (emoji) => {
    var newEmoji = new Emoji("00000000-0000-0000-0000-000000000000", emoji.group, emoji.value);
    return SubmitStuff(newEmoji);
}
const submitText = (text) => {
    var newText = new Text("00000000-0000-0000-0000-000000000000", text.group, text.text);
    return SubmitStuff(newText);
}
const submitImage = async (image) => {
    const uploadedImage = await UploadImage(image.group, image.value);
    if (uploadedImage) {
        var newImage = new Image("00000000-0000-0000-0000-000000000000", image.group, image.title, uploadedImage.pictureId);
        return await SubmitStuff(newImage);
    }
    else return;
}

const buildPaths = (path, group) => {
    return [
        {
            path: path,
            element: <MyStuffPage group={group} />,
        },
        {
            path: path + "/add",
            element: <AddGroupStuffPage group={group}/>
        },
        {
            path: path + "/add/link",
            element: <AddLink group={group} return={"/mystuff/" + path} submit={submitLink}/>
        },
        {
            path: path + "/add/text",
            element: <AddText group={group} return={"/mystuff/" + path} submit={submitText}/>
        },
        {
            path: path + "/add/emoji",
            element: <AddEmoji group={group} return={"/mystuff/" + path} submit={submitEmoji}/>
        },
        {
            path: path + "/add/image",
            element: <AddImage group={group} return={"/mystuff/" + path} submit={submitImage}/>
        }
    ]
} 

const children = [{
    path: "",
    element: <MyStuff />
},
{
    path: "add",
    element: <AddStuffPage/>
}
].concat(buildPaths("whoiam", StuffGroup.WhoIAm))
 .concat(buildPaths("whoiwanttobe", StuffGroup.WantToBe))
 .concat(buildPaths("howillgetthere", StuffGroup.GetThere))

const MyStuffRoutes = {
    path: "mystuff",
    element: <Outlet />,
    children: children
};

export default MyStuffRoutes;
