import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SecureImage from "../../shared/auth/SecureImage";
import { ItemArea } from "../../shared/Events";
import { AuthContext } from "../auth/Auth";
import { NotificationContext } from "../notifications/Notifications";
import { GetPractitionerBio } from "./Data";

import "./practitioner.scss";

function Bio() {
    const authContext = useContext(AuthContext);
    const user = authContext.user();
    const practitionerId = user.practitionerId;
    const notificationContext = useContext(NotificationContext);

    const [practitionerName, setPractitionerName] = useState("");
    const [practitionerBio, setPractitionerBio] = useState("");

    useEffect(() => {
        GetPractitionerBio(practitionerId).then(practitioner => {
            setPractitionerName(practitioner.name);
            setPractitionerBio(practitioner.bio);
        })
    }, [practitionerId]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Cases);
    }, [notificationContext]);

    const profileUrl = "media/profile-picture/" + practitionerId;

    return (
        <div className="practitioner content">
            <div className="practitioner-details">
                <SecureImage className='profile' url={profileUrl} />
                <h1>{practitionerName.first} {practitionerName.nickname} {practitionerName.last}</h1>
                <span>{practitionerBio}</span>
            </div>
            <div className="buttons">
                <Link to="/messages/create" className='button'>Message {practitionerName.first}</Link>
                <Link to="/appointments" className='button button-tertiary'>Your appointments with {practitionerName.first}</Link>
            </div>
        </div>
    )
}

export default Bio;