import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetMyStuff, DeleteStuff } from "./Data";
import { mapComponents, mapTitle, mapControlComponents } from "../../shared/mystuff/MyStuff";
import { AddGroupStuff, AddStuff } from "../../shared/mystuff/AddStuff";
import { StuffGroup } from "../../shared/mystuff/Stuff";
import { HalfPage, IconButton } from "../../shared/layout/Layout";
import './mystuff.scss';
import * as Icon from "../../shared/Icons";
import { ItemArea } from "../../shared/Events";
import { Modal } from "../../shared/Modals";
import { NotificationContext } from "../notifications/Notifications";
import Scrollbars from "react-custom-scrollbars-2";
import BackButton from "../../shared/navigation";

const AddStuffPage = function(props){
    const navigate = useNavigate();
    return (
        <HalfPage className="mystuff-addpage">
            <h1>Add more stuff</h1>
            <AddStuff/>
            <button onClick={() => navigate(-1)} className="button-secondary">Cancel</button>
        </HalfPage>
    )
}

const AddGroupStuffPage = function(props){
    const group = props.group;
    const navigate = useNavigate();

    return (
        <HalfPage className="mystuff-addpage">
            <b>Add new stuff to '{mapTitle(Number(group))}'</b>
            <p>You could explore this with your worker when you see them.</p>
            <AddGroupStuff group={group}/>
            <button onClick={() => navigate(-1)} className="button-secondary">Cancel</button>
        </HalfPage>
    )
}

const MyStuffPage = function (props) {
    const notificationContext = useContext(NotificationContext);
    const [group, setGroup] = useState(Number(props.group));
    const [stuff, setStuff] = useState([]);
    const [deleteMode, setDeleteMode] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteStuff, setDeleteStuff] = useState([]);
    const navigate = useNavigate();
    const scrollRef = useRef();
    const pageSize = 6;

    useEffect(() => {
        GetMyStuff(group, 18, 0).then(s => {
            setStuff(s);
            if(scrollRef.current !== null){
                scrollRef.current.scrollToTop();
            }
        });
    }, [group]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Stuff);
    }, [notificationContext]);
    
    let title = mapTitle(Number(group));

    let controlCheck = (id, e) => {
        if(e.target.checked){
            setDeleteStuff(s => {
                return s.concat([id]);
            });
        }else{
            setDeleteStuff(s => {
                return s.filter(x => x !== id);
            });
        }
    }

    const EmptyStuff = () => (
        <p>
            You have not added stuff to '{title}' yet.
            <br /><br />
            To add stuff click 'Add'.
        </p>
    )

    const EmptyDeleteStuff = () => (
        <p>
            You have not added stuff to '{title}' yet.
            <br /><br />
            To add stuff click 'Cancel' and then 'Add'.
        </p>
    )

    const deleteComponents = stuff.map(s => mapControlComponents(deleteStuff, controlCheck, s));
    const stuffComponents = stuff.map(mapComponents);

    const description = stuff.length === 0 ? deleteMode ? EmptyDeleteStuff() : EmptyStuff() : <></>;

    function changeNavBar(e) {
        var target = Number(e.target.value);
        switch (target) {
            case StuffGroup.WhoIAm:
                navigate("/mystuff/whoiam", { replace: true });
                break;
            case StuffGroup.WantToBe:
                navigate("/mystuff/whoiwanttobe", { replace: true });
                break;
            case StuffGroup.GetThere:
                navigate("/mystuff/howillgetthere", { replace: true });
                break;
            default:
                break;
        }
        setGroup(target);
    }

    const submit = () => {
        navigate("add");
    }
    const cancelDelete = () => {
        setDeleteMode(false);
        setDeleteStuff([]);
    }
    const preDelete = () => {
        if(deleteStuff.length === 0)    //error
            return;
        else
            setShowDeleteModal(true);
    }
    const doDelete = () => {
        DeleteStuff(deleteStuff).then(() => {
            // remove stuff from the list
            setStuff(su => {
                let u = su.filter(s => !deleteStuff.includes(s.id));
                return u;
            })
            setDeleteMode(false);
            setDeleteStuff([]);
            setShowDeleteModal(false);
        });
    }

    const onScroll = () => {
        if(scrollRef.current === null) return;
        let current = scrollRef.current;

        let bottom = current.getScrollHeight() - current.getClientHeight() - current.getScrollTop();
        if(Math.abs(bottom) <= 1){
            GetMyStuff(group, stuff.length + pageSize, stuff.length).then(
                r => setStuff(s => s.concat(r))
            );
        }
    }

    const deleteButton = deleteMode
         ? (<>
            <IconButton icon={<Icon.Bin />} submit={preDelete} className="button-delete">Delete</IconButton>
            <Modal show={showDeleteModal} handleClose={() => setShowDeleteModal(false)} submit={doDelete} text="Are you sure you want to delete this stuff?" />
            <IconButton icon={<Icon.Delete />} submit={cancelDelete} className="button-cancel">Cancel</IconButton>
         </>)
        : <IconButton icon={<Icon.Bin />} submit={() => setDeleteMode(true)} className="button-delete">Delete</IconButton>;

    const addButton = deleteMode
            ? <></>
            : <IconButton icon={<Icon.Add />} submit={submit} className="button-add">Add</IconButton>;

    return (
        <div className="mystuff content-minimal">
            <BackButton />
            <h1>{title}</h1>
            <div className='bar'>
                <select onChange={changeNavBar} defaultValue={group}>
                    <option disabled>All my stuff</option>
                    <option value="0">Who I am</option>
                    <option value="1">Who I want to be</option>
                    <option value="2">How I'll get there</option>
                </select>
                <div className="buttons">
                    {deleteButton}
                    {addButton}
                </div>
            </div>
            {description}
            <Scrollbars className='grid' ref={scrollRef} onScroll={onScroll}>
            <div className="grid-container" >
                    {deleteMode ? deleteComponents : stuffComponents}
                </div>
            </Scrollbars>
        </div>
    )
}

export { MyStuffPage, AddStuffPage, AddGroupStuffPage };