import { Outlet } from "react-router-dom";
import { Goals, SuggestGoal } from "./Goals";
import TasksRoutes from "./tasks/Routes"

const GoalsRoutes = {
    path: "goals",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Goals />
        },
        {
            path: "suggest",
            element: <SuggestGoal/>
        },
        TasksRoutes
    ]
};

export default GoalsRoutes;
